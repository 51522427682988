import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Importing the translation files
import translationEN from "./locales/en/translation.json";
// import translationES from "./locales/es/translation.json";
import translationES from "./locales/es/translation.json";
import translationEL from "./locales/el/translation.json";
import translationSQ from "./locales/sq/translation.json";
// ... other language imports

// Retrieve language code from local storage or default to 'es'
const savedLanguage = localStorage.getItem("appLanguage") || "es";

// Setting up the i18next configuration
i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources: {
      en: { translation: translationEN },
      es: { translation: translationES },
      el: { translation: translationEL },
      sq: { translation: translationSQ },
      // ... other languages
    },
    lng: savedLanguage, // Use saved language as default
    fallbackLng: "es", // Fallback language
    keySeparator: false, // To support nested translations

    interpolation: {
      escapeValue: false, // React already safes from XSS
    },
  });

export default i18n;
