import React, { useState, useEffect } from "react";
import Navigator from "../../Utils/Navigation/Navigator";
import Footer from "../../Utils/Footer/Footer";
import { FaBusAlt } from "react-icons/fa";
import { useTranslation, Trans } from "react-i18next";

import "./styles/Buses.css";

function Buses() {
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("appLanguage") || ""
  );

  useEffect(() => {
    window.scrollTo(-10, 0);
    const storedLanguage = localStorage.getItem("appLanguage");
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
    }
  }, []);

  return (
    <article className="MAINbox">
      <div className="BusesPageBox">
        <Navigator />
        <div className="BusesBox">
          <h3
            className="BusesTitle"
            style={
              selectedLanguage === "el" ? { fontFamily: "Times New Roman" } : {}
            }
          >
            <FaBusAlt /> {t("BusesPage_Title")}
          </h3>
          <p className="BusesParagraph">{t("BusesPage_Paragraph_1")}</p>
          <h5 className="BusesSubTitle">{t("BusesPage_SubTitle_1")}</h5>

          <p className="BusesParagraph">
            <Trans i18nKey="BusesPage_Paragraph_2">
              Departures from the hotels will be at <strong>7:30 pm</strong>. We
              ask that you be ready 10 minutes before departure, as the buses
              will leave very punctual to avoid delays. Thank you.
            </Trans>
          </p>

          <p className="BusesParagraph">
            <Trans i18nKey="BusesPage_Paragraph_3">
              The different laps from Villanueva will be: the first at{" "}
              <strong>2:30 am</strong>, the second at <strong>4:00 am</strong>,
              the third at <strong>5:30</strong> and the last at{" "}
              <strong>7:00 am</strong>.
            </Trans>
          </p>

          <p className="BusesParagraph">
            <Trans i18nKey="BusesPage_Paragraph_4">
              The different laps from Villanueva will be: the first at{" "}
              <strong>2:30 am</strong>, the second at <strong>4:00 am</strong>,
              the third at <strong>5:30</strong> and the last at{" "}
              <strong>7:00 am</strong>.
            </Trans>
            <a
              href="https://www.google.es/maps/place/37%C2%B010'35.7%22N+3%C2%B036'51.4%22W/@37.17659,-3.614267,17z/data=!3m1!4b1!4m4!3m3!8m2!3d37.17659!4d-3.614267?entry=ttu"
              target="_blank"
              rel="noopener noreferrer"
              className="linkToMap"
            >
              {t("BusesPage_ViewMap")}
            </a>
          </p>

          <p className="BusesParagraph">
            <Trans i18nKey="BusesPage_Paragraph_5">
              H͟y͟p͟e͟r͟c͟o͟r͟: The pick-up will take place at the roundabout located
              near the Hipercor de Arabial, which has an exit to the highway at{" "}
              <strong>19:10 pm</strong>.
            </Trans>
            <a
              href="https://www.google.es/maps/search/37.168908,+-3.607887?entry=tts"
              target="_blank"
              rel="noopener noreferrer"
              className="linkToMap"
            >
              {t("BusesPage_ViewMap")}
            </a>
          </p>

          <p className="BusesParagraph">
            <Trans i18nKey="BusesPage_Paragraph_6">
              The different laps from Villanueva will be: the first at{" "}
              <strong>2:30 am</strong>, the second at <strong>4:00 am</strong>,
              the third at <strong>5:30</strong> and the last at{" "}
              <strong>7:00 am</strong>.
            </Trans>
          </p>

          <h5 className="BusesSubTitle">{t("BusesPage_SubTitle_2")}</h5>

          <p className="BusesParagraph">
            <Trans i18nKey="BusesPage_Paragraph_7">
              The different laps from Villanueva will be: the first at{" "}
              <strong>2:30 am</strong>, the second at <strong>4:00 am</strong>,
              the third at <strong>5:30</strong> and the last at{" "}
              <strong>7:00 am</strong>.
            </Trans>
          </p>
        </div>
      </div>
      <Footer />
    </article>
  );
}

export default Buses;
