/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Navigator from "../../Utils/Navigation/Navigator";
import Footer from "../../Utils/Footer/Footer";
import { IoIosMail } from "react-icons/io";
import axios from "axios";
import { useTranslation, Trans } from "react-i18next";
import LoadingDots from "../../LoadingDots/LoadingDots";
import "./styles/ConfirmYourAttendance.css";

function ConfirmYourAttendance() {
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("appLanguage") || ""
  );

  useEffect(() => {
    window.scrollTo(-10, 0);
    const storedLanguage = localStorage.getItem("appLanguage");
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
    }
  }, []);

  const [formData, setFormData] = useState({
    adultsNames: ["", "", "", "", ""],
    kidsNames: ["", "", "", "", ""],
    // allergies: "",
    attending: "",
    adults: null,
    kids: null,
    hiredBus: null,
    message: "",
  });

  const [validationMessages, setValidationMessages] = useState({});
  const [pressedSubmit, setPressedSubmit] = useState(false);
  const [sendPressed, setSendPress] = useState(false);

  useEffect(() => {
    if (pressedSubmit) {
      const messages = validateForm();
      if (Object.keys(messages).length > 0) {
        setValidationMessages(messages);
      }
    }
  }, [formData, pressedSubmit]);

  const validateForm = () => {
    let messages = {};
    messages.adultsNames = [];
    messages.kidsNames = [];
    for (let index = 0; index < formData.adults; index++) {
      if (!formData.adultsNames[index].trim()) {
        messages.adultsNames[index] = t(
          "ConfirmYourAttendancePage_Error_Full_Name"
        );
      } else {
        messages.adultsNames[index] = "";
      }
    }

    if (!formData.attending.trim()) {
      messages.attending = t("ConfirmYourAttendancePage_Error_Attendance");
    }
    if (formData.attending === "yes") {
      if (!formData.adults) {
        messages.adults = t("ConfirmYourAttendancePage_Error_Adults");
      }
      if (!formData.kids && formData.kids !== 0) {
        messages.kids = t("ConfirmYourAttendancePage_Error_Kids");
      }
      if (!formData.hiredBus || !formData.hiredBus.trim()) {
        messages.hiredBus = t("ConfirmYourAttendancePage_Error_Hired_Bus");
      }
      // if (!formData.allergies) {
      //   messages.allergies = "Allergies confirmation is required";
      // }

      for (let index = 0; index < formData.kids; index++) {
        if (!formData.kidsNames[index].trim()) {
          messages.kidsNames[index] = t(
            "ConfirmYourAttendancePage_Error_Full_Name"
          );
        } else {
          messages.kidsNames[index] = "";
        }
      }
    } else {
      for (let index = 0; index < 1; index++) {
        if (!formData.adultsNames[index].trim()) {
          messages.adultsNames[index] = t(
            "ConfirmYourAttendancePage_Error_Full_Name"
          );
        } else {
          messages.adultsNames[index] = "";
        }
      }
    }
    // ... add other validation checks as needed

    return messages;
  };

  const handleChange = (e) => {
    if (pressedSubmit) {
      const messages = validateForm();
      if (Object.keys(messages).length > 0) {
        setValidationMessages(messages);
      }
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeAdultsName = (e, index) => {
    const updatedNames = [...formData.adultsNames];
    // Update the adultsNames at the specified index
    updatedNames[index] = e.target.value;

    // Set the updated names array in the state
    setFormData({
      ...formData,
      adultsNames: updatedNames,
    });
  };

  const handleChangeKidsName = (e, index) => {
    const updatedNames = [...formData.kidsNames];
    // Update the kidsNames at the specified index
    updatedNames[index] = e.target.value;

    // Set the updated names array in the state
    setFormData({
      ...formData,
      kidsNames: updatedNames,
    });
  };

  const handleSubmit = async (e) => {
    setPressedSubmit(true);
    setSendPress(true);
    const messages = validateForm();

    // Check if there are validation messages excluding adultsNames and kidsNames
    const hasOtherValidationErrors = Object.keys(messages).some(
      (key) => key !== "adultsNames" && key !== "kidsNames" && messages[key]
    );

    // Function to check if all non-empty names in the arrays have length > 1
    function areNamesValid(namesArray) {
      // Return true if the array is empty
      if (namesArray.length === 0) {
        return true;
      }

      // Return true if all elements are empty strings
      const newarray = namesArray.every((name) => name.length === 0);
      return newarray;
    }

    if (
      hasOtherValidationErrors ||
      !areNamesValid(messages.adultsNames) ||
      !areNamesValid(messages.kidsNames)
    ) {
      e.preventDefault();
      setSendPress(false);
      setValidationMessages(messages);
      return; // Stop the form submission if there are validation errors
    }
  };

  // Render divs based on newRows
  const renderAdultsDivs = () => {
    let divs = [];
    if (formData.adults) {
      for (let i = 0; i < formData.adults; i++) {
        divs.push(
          <div key={i} className="InputsFormatting">
            <label>
              #{i + 1} {t("ConfirmYourAttendancePage_Form_Name")}
            </label>
            <input
              type="text"
              name="adultsNames"
              value={formData.adultsNames[i]}
              onChange={(event) => handleChangeAdultsName(event, i)}
            />
            {validationMessages.adultsNames && (
              <span className="error-message">
                {validationMessages.adultsNames[i]}
              </span>
            )}
          </div>
        );
      }
    }
    return divs;
  };

  // Render divs based on newRows
  const renderKidsDivs = () => {
    let divs = [];
    if (formData.kids) {
      for (let i = 0; i < formData.kids; i++) {
        divs.push(
          <div key={i} className="InputsFormatting">
            <label>
              #{i + 1} {t("ConfirmYourAttendancePage_Form_Name")}
            </label>
            <input
              type="text"
              name="name"
              value={formData.kidsNames[i]}
              onChange={(event) => handleChangeKidsName(event, i)}
            />
            {validationMessages.kidsNames && (
              <span className="error-message">
                {validationMessages.kidsNames[i]}
              </span>
            )}
          </div>
        );
      }
    }
    return divs;
  };

  return (
    <article className="MAINbox">
      <div className="ConfirmYourAttendancePageBox">
        <Navigator />
        <div className="ConfirmYourAttendanceBox">
          <h3
            className="ConfirmYourAttendanceTitle"
            style={
              selectedLanguage === "el" ? { fontFamily: "Times New Roman" } : {}
            }
          >
            {t("ConfirmYourAttendancePage_Title")}
          </h3>
          <p className="ConfirmYourAttendanceParagraph">
            {t("ConfirmYourAttendancePage_Paragraph_1")}
          </p>
          <p className="ConfirmYourAttendanceParagraph">
            {t("ConfirmYourAttendancePage_Paragraph_2")}
          </p>
          <form
            // onSubmit={handleSubmit}
            className="attendanceForm"
            method="POST"
            name="Confirm_Attendance"
            data-netlify="true"
            onSubmit="submit"
          >
            <input type="hidden" name="form-name" value="Confirm_Attendance" />
            <div className="attendanceFormIn">
              <div className="attendingFormLeftSide">
                <div className="InputsFormatting">
                  <label>
                    {t("ConfirmYourAttendancePage_Form_Attendance")}
                  </label>
                  <div className="attendance-choice">
                    <div hidden>
                      <label>Attendance:</label>
                      <input
                        type="text"
                        name="attendance"
                        value={formData.attending}
                      />
                    </div>
                    <div
                      className={`choice-box ${
                        formData.attending === "yes" ? "selected" : ""
                      }`}
                      onClick={() => {
                        setValidationMessages({
                          ...validationMessages,
                          attending: false,
                        });
                        setFormData({ ...formData, attending: "yes" });
                      }}
                    >
                      {t("ConfirmYourAttendancePage_Form_Attendance_Yes")}
                    </div>
                    <div
                      className={`choice-box ${
                        formData.attending === "no" ? "selected" : ""
                      }`}
                      onClick={() => {
                        setValidationMessages({
                          ...validationMessages,
                          attending: false,
                        });
                        setFormData({ ...formData, attending: "no" });
                      }}
                    >
                      {t("ConfirmYourAttendancePage_Form_Attendance_No")}
                    </div>
                  </div>
                  {validationMessages.attending && (
                    <span className="error-message">
                      {validationMessages.attending}
                    </span>
                  )}
                </div>
                {formData.attending === "yes" && (
                  <div className="ConfirmAttend">
                    <div hidden>
                      <label>Adults Names:</label>
                      <input
                        type="text"
                        name="adultsName"
                        value={formData.adultsNames}
                      />
                    </div>
                    <div hidden>
                      <label>Kids Names:</label>
                      <input
                        type="text"
                        name="kidsNames"
                        value={formData.kidsNames}
                      />
                    </div>

                    <div className="InputsFormatting">
                      <div hidden>
                        <label>Adults:</label>
                        <input
                          type="number"
                          name="adults"
                          value={formData.adults}
                        />
                      </div>
                      <label>
                        {t("ConfirmYourAttendancePage_Form_Adults")}
                      </label>
                      <div className="attendance-choice">
                        <div
                          className={`choice-box ${
                            formData.adults === 1 ? "selected" : ""
                          }`}
                          onClick={() => {
                            setValidationMessages({
                              ...validationMessages,
                              adults: false,
                            });
                            setFormData({ ...formData, adults: 1 });
                          }}
                        >
                          1
                        </div>
                        <div
                          className={`choice-box ${
                            formData.adults === 2 ? "selected" : ""
                          }`}
                          onClick={() => {
                            setValidationMessages({
                              ...validationMessages,
                              adults: false,
                            });
                            setFormData({ ...formData, adults: 2 });
                          }}
                        >
                          2
                        </div>
                        <div
                          className={`choice-box ${
                            formData.adults === 3 ? "selected" : ""
                          }`}
                          onClick={() => {
                            setValidationMessages({
                              ...validationMessages,
                              adults: false,
                            });
                            setFormData({ ...formData, adults: 3 });
                          }}
                        >
                          3
                        </div>
                        <div
                          className={`choice-box ${
                            formData.adults === 4 ? "selected" : ""
                          }`}
                          onClick={() => {
                            setValidationMessages({
                              ...validationMessages,
                              adults: false,
                            });
                            setFormData({ ...formData, adults: 4 });
                          }}
                        >
                          4
                        </div>
                        <div
                          className={`choice-box ${
                            formData.adults === 5 ? "selected" : ""
                          }`}
                          onClick={() => {
                            setValidationMessages({
                              ...validationMessages,
                              adults: false,
                            });
                            setFormData({ ...formData, adults: 5 });
                          }}
                        >
                          5
                        </div>
                      </div>
                      {validationMessages.adults && (
                        <span className="error-message">
                          {validationMessages.adults}
                        </span>
                      )}
                      {renderAdultsDivs()}
                    </div>
                    <div className="InputsFormatting">
                      <div hidden>
                        <label>Kids:</label>
                        <input type="text" name="kids" value={formData.kids} />
                      </div>
                      <label>{t("ConfirmYourAttendancePage_Form_Kids")}</label>
                      <div className="attendance-choice">
                        <div
                          className={`choice-box ${
                            formData.kids === 0 ? "selected" : ""
                          }`}
                          onClick={() => {
                            setValidationMessages({
                              ...validationMessages,
                              kids: false,
                            });
                            setFormData({ ...formData, kids: 0 });
                          }}
                        >
                          0
                        </div>
                        <div
                          className={`choice-box ${
                            formData.kids === 1 ? "selected" : ""
                          }`}
                          onClick={() => {
                            setValidationMessages({
                              ...validationMessages,
                              kids: false,
                            });
                            setFormData({ ...formData, kids: 1 });
                          }}
                        >
                          1
                        </div>
                        <div
                          className={`choice-box ${
                            formData.kids === 2 ? "selected" : ""
                          }`}
                          onClick={() => {
                            setValidationMessages({
                              ...validationMessages,
                              kids: false,
                            });
                            setFormData({ ...formData, kids: 2 });
                          }}
                        >
                          2
                        </div>
                        <div
                          className={`choice-box ${
                            formData.kids === 3 ? "selected" : ""
                          }`}
                          onClick={() => {
                            setValidationMessages({
                              ...validationMessages,
                              kids: false,
                            });
                            setFormData({ ...formData, kids: 3 });
                          }}
                        >
                          3
                        </div>
                        <div
                          className={`choice-box ${
                            formData.kids === 4 ? "selected" : ""
                          }`}
                          onClick={() => {
                            setValidationMessages({
                              ...validationMessages,
                              kids: false,
                            });
                            setFormData({ ...formData, kids: 4 });
                          }}
                        >
                          4
                        </div>
                      </div>
                      {validationMessages.kids && (
                        <span className="error-message">
                          {validationMessages.kids}
                        </span>
                      )}
                      {renderKidsDivs()}
                    </div>
                    <div className="InputsFormatting">
                      <label>
                        {t("ConfirmYourAttendancePage_Chartered_Bus_Title")}
                      </label>
                      <div hidden>
                        <label>Hired Bus:</label>
                        <input
                          type="text"
                          name="hiredBus"
                          value={formData.hiredBus}
                        />
                      </div>
                      <div className="HiredBus">
                        <div
                          className={`choice-box ${
                            formData.hiredBus === "yes" ? "selected" : ""
                          }`}
                          onClick={() => {
                            setValidationMessages({
                              ...validationMessages,
                              hiredBus: false,
                            });
                            setFormData({ ...formData, hiredBus: "yes" });
                          }}
                        >
                          {t(
                            "ConfirmYourAttendancePage_Chartered_Bus_Choice_1"
                          )}
                        </div>
                        <div
                          className={`choice-box ${
                            formData.hiredBus === "no" ? "selected" : ""
                          }`}
                          onClick={() => {
                            setValidationMessages({
                              ...validationMessages,
                              hiredBus: false,
                            });
                            setFormData({ ...formData, hiredBus: "no" });
                          }}
                        >
                          {t(
                            "ConfirmYourAttendancePage_Chartered_Bus_Choice_2"
                          )}
                        </div>
                      </div>
                      {validationMessages.hiredBus && (
                        <span className="error-message">
                          {validationMessages.hiredBus}
                        </span>
                      )}
                    </div>
                    {/* <div className="InputsFormatting">
                      <label>
                        {t("ConfirmYourAttendancePage_Form_Allergies")}
                      </label>
                      <textarea
                        name="allergies"
                        placeholder={t(
                          "ConfirmYourAttendancePage_Form_Allergies_Placeholder"
                        )}
                        value={formData.allergies}
                        onChange={handleChange}
                      />
                      {validationMessages.allergies && (
                        <span className="error-message">
                          {validationMessages.allergies}
                        </span>
                      )}
                    </div> */}
                  </div>
                )}
                {formData.attending === "no" && (
                  <div className="InputsFormatting">
                    <label>{t("ConfirmYourAttendancePage_Form_Name")}</label>
                    <input
                      type="text"
                      name="adultsNames"
                      value={formData.adultsNames[0]}
                      onChange={(event) => handleChangeAdultsName(event, 0)}
                    />
                    {validationMessages.adultsNames && (
                      <span className="error-message">
                        {validationMessages.adultsNames[0]}
                      </span>
                    )}
                  </div>
                )}
              </div>
              <div className="AttendingFormMessage">
                <label>{t("ConfirmYourAttendancePage_Form_Message")}</label>
                <textarea
                  name="message"
                  placeholder={t(
                    "ConfirmYourAttendancePage_Form_Message_Placeholder"
                  )}
                  value={formData.message}
                  onChange={handleChange}
                />
              </div>
            </div>
            <button type="submit" className="btn" onClick={handleSubmit}>
              {sendPressed ? (
                <LoadingDots />
              ) : (
                t("ConfirmYourAttendancePage_Form_Btn")
              )}
            </button>
          </form>

          <p
            className="ConfirmYourAttendanceParagraph"
            style={{ marginTop: "40px" }}
          >
            {t("ConfirmYourAttendancePage_Contact")}
          </p>
          <p className="ConfirmYourAttendanceParagraph">
            <strong>Antouela:</strong> 00353830679017
            <br />
            <strong>Nacho:</strong> 00353830643315
          </p>
        </div>
      </div>
      <Footer />
    </article>
  );
}

export default ConfirmYourAttendance;
