import React, { useState, useEffect } from "react";
import Navigator from "../../Utils/Navigation/Navigator";
import Footer from "../../Utils/Footer/Footer";
import { FaCameraRetro } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import "./styles/Album.css";

function Album() {
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("appLanguage") || ""
  );

  useEffect(() => {
    window.scrollTo(-10, 0);
    const storedLanguage = localStorage.getItem("appLanguage");
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
    }
  }, []);

  return (
    <article className="MAINbox">
      <div className="AlbumPageBox">
        <Navigator />
        <div className="AlbumBox">
          <h3
            className="AlbumTitle"
            style={
              selectedLanguage === "el" ? { fontFamily: "Times New Roman" } : {}
            }
          >
            <FaCameraRetro /> {t("AlbumPage_Title")}
          </h3>
          <p className="AlbumParagraph">{t("AlbumPage_Paragraph_1")}</p>

          <p className="AlbumParagraph">{t("AlbumPage_Paragraph_2")}</p>

          <p className="AlbumParagraph">{t("AlbumPage_Paragraph_3")}</p>
        </div>
        <a
          href="https://photos.app.goo.gl/jExvBTkUEFyRKc9eA"
          className="photosBtn"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("AlbumPage_Button")}
        </a>
      </div>
      <Footer />
    </article>
  );
}

export default Album;
