import React, { useState, useEffect } from "react";
import Navigator from "../../Utils/Navigation/Navigator";
import Footer from "../../Utils/Footer/Footer";
import { IoGift } from "react-icons/io5";
import { useTranslation } from "react-i18next";

import "./styles/WeddingGift.css";

function WeddingGift() {
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("appLanguage") || ""
  );

  useEffect(() => {
    window.scrollTo(-10, 0);
    const storedLanguage = localStorage.getItem("appLanguage");
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
    }
  }, []);

  return (
    <article className="MAINbox">
      <div className="WeddingGiftPageBox">
        <Navigator />
        <div className="WeddingGiftBox">
          <h3
            className="WeddingGiftTitle"
            style={
              selectedLanguage === "el" ? { fontFamily: "Times New Roman" } : {}
            }
          >
            <IoGift /> {t("WeddingGiftPage_Title")}
          </h3>
          <p className="WeddingGiftParagraph">
            {t("WeddingGiftPage_Paragraph")}
          </p>

          <p className="WeddingGiftParagraphBank">
            <br />
            ES59 2100 2904 0802 0033 1486
            <br />
            BIZUM: 00353830643315
            <br />
            <br />
            REVOLUT <br />
            IE27 REVO 9903 6011 1326 49
            <br />
            (@ignaci3a7i)
          </p>

          <p className="WeddingGiftParagraph" style={{ marginTop: "40px" }}>
            {t("WeddingGiftPage_ThankYou")}
          </p>
        </div>
      </div>
      <Footer />
    </article>
  );
}

export default WeddingGift;
