import React from "react";
import "./footer.css";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="NamesInTitleOnFooter">Antouela & Nacho</div>
      <div className="DateInTitleOnFooter">24/08/2024</div>
    </footer>
  );
}
