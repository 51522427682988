import React, { useState, useEffect } from "react";
import Navigator from "../../Utils/Navigation/Navigator";
import Footer from "../../Utils/Footer/Footer";
import Image from "../../images/Ceremony.webp";
import { GiPartyFlags } from "react-icons/gi";
import { useTranslation, Trans } from "react-i18next";

import "./styles/CeremonyAndCelebration.css";

function CeremonyAndCelebration() {
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("appLanguage") || ""
  );

  useEffect(() => {
    window.scrollTo(-10, 0);
    const storedLanguage = localStorage.getItem("appLanguage");
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
    }
  }, []);

  return (
    <article className="MAINbox">
      <div className="CeremonyAndCelebrationPageBox">
        <Navigator />
        <div className="CeremonyAndCelebrationBox">
          <h3
            className="CeremonyAndCelebrationTitle"
            style={
              selectedLanguage === "el" ? { fontFamily: "Times New Roman" } : {}
            }
          >
            <GiPartyFlags /> {t("CeremonyAndCelebrationPage_Title")}
          </h3>
          <div className="fixedWidth">
            <p className="CeremonyAndCelebrationParagraph">
              <Trans i18nKey="CeremonyAndCelebrationPage_Paragraph_1">
                The civil ceremony will begin at <strong>8:30 pm</strong> and
                will take place at the Villanueva family farm located between
                Doña Mencía and Cabra, province of Córdoba.
              </Trans>
            </p>
            <p className="CeremonyAndCelebrationParagraph">
              <Trans i18nKey="CeremonyAndCelebrationPage_Paragraph_2">
                Likewise, the celebration will be on said farm and will begin on
                the
                <strong> 9:30 pm</strong>.
              </Trans>
            </p>

            <img
              src={Image}
              alt="Ceremony and celebration"
              className="CeremonyAndCelebrationImg"
            />
            <p className="CeremonyAndCelebrationParagraph">
              {t("CeremonyAndCelebrationPage_Paragraph_3")}
              <a
                href="https://www.google.com/maps?q=Restaurante+El+Cordobes,+Ctra.+de+La+Malah%C3%A1,+5,5,+18110,+Granada,+Espa%C3%B1a&ftid=0xd71f9111cedd1c7:0xa54b6283e6330e79&entry=gps&lucs=,94216395,47071704,94206166,47069508,94214172,94203019,47084304,94208458,94208447,94213318&g_ep=CAISDTYuMTExLjIuNTQ1MjAYACCenQoqWiw5NDIxNjM5NSw0NzA3MTcwNCw5NDIwNjE2Niw0NzA2OTUwOCw5NDIxNDE3Miw5NDIwMzAxOSw0NzA4NDMwNCw5NDIwODQ1OCw5NDIwODQ0Nyw5NDIxMzMxOEICSUU%3D&g_st=ifm"
                target="_blank"
                rel="noopener noreferrer"
                className="linkToMap"
              >
                {t("CeremonyAndCelebrationPage_ViewMap")}
              </a>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </article>
  );
}

export default CeremonyAndCelebration;
