import React from "react";
import "./styles/error.css";
import { useTranslation } from "react-i18next";

export default function ErrorPage() {
  const { t } = useTranslation();

  return (
    <div className="not-found-container">
      <h1 className="not-found-title">{t("ErrorPage_Title")}</h1>
      <p className="not-found-text">{t("ErrorPage_Paragraph")}</p>
      <a href="/" className="not-found-link">
        {t("ErrorPage_Btn")}
      </a>
    </div>
  );
}
