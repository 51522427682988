import React, { useEffect, useState } from "react";
import Navigator from "../../Utils/Navigation/Navigator";
import { IoIosArrowDown } from "react-icons/io";
import Image from "../../images/retrato.webp";
import photos from "../../images/Photos.webp";
import ceremonyImage from "../../images/Ceremony_home_page_img.webp";
import busImage from "../../images/bus.webp";
import { MdEmail } from "react-icons/md";
import { IoGift } from "react-icons/io5";
import { useTranslation, Trans } from "react-i18next";

import "./styles/home.css";
import { useNavigate } from "react-router-dom";
import WelcomeImage from "../../images/motivo.webp";

function Home() {
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("appLanguage") || ""
  );

  useEffect(() => {
    const storedLanguage = localStorage.getItem("appLanguage");
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
    }
  }, []);

  const { t } = useTranslation();

  const navigator = useNavigate();

  const handleClick = () => {
    const imageScroll = document.getElementById("AntouelaNacho");

    if (imageScroll) {
      imageScroll.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <article className="home">
      <Navigator />
      <div className="homeMessage">
        <img src={WelcomeImage} alt="welcome" className="welcomeImage" />

        <p className="homeWeddingParagraph">
          <Trans i18nKey="HomePage_WeddingParagraph">
            Dear family and friends, it makes us very happy to inform you
            that... We are getting married!
            <br />
            <br className="ExtraSpace" />
            The Wedding will take place on <strong>24.08.2024</strong> and we
            would love to share this special day with you.
          </Trans>
        </p>
        <IoIosArrowDown className="downArrow" onClick={handleClick} />
      </div>
      <div className="ImageBox">
        <img src={Image} alt="Antouela & Nacho" id="AntouelaNacho" />
        <p className="detailedInformation">
          {t("HomePage_GeneralInformationTitle")}
        </p>
        <div className="CeremonyAndCelebration">
          <img src={ceremonyImage} alt="Ceremony and celebration" />
          <strong>{t("HomePage_CeremonyAndCelebrationTitle")}</strong>
          <p>{t("HomePage_CeremonyAndCelebrationAddress_1")}</p>
          <p>{t("HomePage_CeremonyAndCelebrationAddress_2")}</p>
          <button
            className="btn"
            style={{ marginBottom: 20 }}
            onClick={() => {
              navigator("/Ceremony-and-celebration");
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            {t("MORE_INFORMATION")}
          </button>
        </div>
        <div className="BoxContainer">
          <div className="BusesAlbum">
            <img src={busImage} alt="Buses" />
            <strong>{t("HomePage_BusesTitle")}</strong>
            <p>{t("HomePage_BusesParagraph")}</p>
            <button
              className="btn"
              onClick={() => {
                navigator("/Buses");
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              {t("MORE_INFORMATION")}
            </button>
          </div>
          <div className="BusesAlbum">
            <img src={photos} alt="Album" />
            <strong>{t("HomePage_AlbumTitle")} </strong>
            <p>{t("HomePage_AlbumParagraph")}</p>
            <button
              className="btn"
              onClick={() => {
                navigator("/Album");
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              {t("MORE_INFORMATION")}
            </button>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "white" }}>
        <div className="confirmAttendance">
          <div className="iconBox">
            <MdEmail />
          </div>
          <h3
            className="boxTitle"
            style={
              selectedLanguage === "el" ? { fontFamily: "Times New Roman" } : {}
            }
          >
            {t("HomePage_ConfirmAttendanceTitle")}
          </h3>
          <p>{t("HomePage_ConfirmAttendanceParagraph")}</p>
          <button
            className="btn ConfirmBtn"
            onClick={() => {
              navigator("/Confirm-your-attendance");
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            {t("HomePage_ConfirmAttendanceButton")}
          </button>
        </div>
        <div className="WeddingGift">
          <div className="iconBox">
            <IoGift />
          </div>
          <h3
            className="boxTitle"
            style={
              selectedLanguage === "el" ? { fontFamily: "Times New Roman" } : {}
            }
          >
            {t("HomePage_WeddingGift")}
          </h3>
          <button
            className="btn"
            onClick={() => {
              navigator("/Wedding-Gift");
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            {t("MORE_INFORMATION")}
          </button>
        </div>
        <div className="SaveDate">
          <h3
            className="boxTitle"
            style={
              selectedLanguage === "el" ? { fontFamily: "Times New Roman" } : {}
            }
          >
            {t("HomePage_SaveDate")}
          </h3>
          <p>{t("HomePage_SaveDateParagraph")}</p>
        </div>
      </div>
    </article>
  );
}

export default Home;
