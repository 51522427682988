/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Navigation.css"; // Importing the CSS file for styling
import { FaHeart, FaHome, FaBus } from "react-icons/fa";
import { FaFlag } from "react-icons/fa6";
import { FaCamera } from "react-icons/fa";
import { IoGift } from "react-icons/io5";
import { IoIosMail, IoIosArrowForward } from "react-icons/io";
import { useTranslation } from "react-i18next";
import GRflag from "../../images/greek-flag.webp";
import SPflag from "../../images/spain-flag.webp";
import ENflag from "../../images/english-flag.webp";
import ALflag from "../../images/albania-flag.webp";

const Navigator = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [scrollingUp, setScrollingUp] = useState(true);

  const location = useLocation();
  const [activeLink, setActiveLink] = useState("home");
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("appLanguage") || ""
  );

  // Function to handle language change
  const handleLanguageChange = (languageCode) => {
    setSelectedLanguage(languageCode);
    localStorage.setItem("appLanguage", languageCode);
    window.location.reload();
    // Here, you can also integrate with i18next or another internationalization library
  };

  // Effect to update the state when the component mounts
  useEffect(() => {
    const storedLanguage = localStorage.getItem("appLanguage");
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
    }
  }, []);

  const { t } = useTranslation();

  useEffect(() => {
    const path = location.pathname;

    if (path === "/") {
      setActiveLink("home");
    } else if (path.startsWith("/Ceremony-and-celebration")) {
      setActiveLink("Ceremony-and-celebration");
    } else if (path.startsWith("/Buses")) {
      setActiveLink("Buses");
    } else if (path.startsWith("/Album")) {
      setActiveLink("Album");
    } else if (path.startsWith("/Wedding-Gift")) {
      setActiveLink("Wedding-Gift");
    } else if (path.startsWith("/Confirm-your-attendance")) {
      setActiveLink("Confirm-your-attendance");
    } else {
      // Default to home if none of the above conditions match
      setActiveLink("home");
    }
  }, [location]);

  // useEffect(() => {
  //   let lastScrollPosition = window.pageYOffset;

  //   const handleScroll = () => {
  //     const currentScrollPosition = window.pageYOffset;

  //     if (currentScrollPosition < 10) {
  //       setScrollingUp(true);
  //     } else {
  //       setScrollingUp(currentScrollPosition < lastScrollPosition);
  //     }

  //     lastScrollPosition = currentScrollPosition;
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="navbar">
      <button
        className={`menu-button ${menuOpen ? "open" : ""}  ${
          scrollingUp ? "visible" : "nonVisible"
        }`}
        onClick={toggleMenu}
      >
        {/* Hamburger Icon */}
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </button>
      <div className={`menu-container ${menuOpen ? "open" : ""}`}>
        <div className="weddingMessage">
          <h5 className="NamesInTitle">Antouela & Nacho</h5>
          <div className="DateInTitle">24/08/2024</div>
        </div>
        <div className="navMenu">
          <Link
            to="/"
            className={
              activeLink === "We-are-getting-married"
                ? "active-link hamburgerNav"
                : "non-active-link hamburgerNav"
            }
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            <FaHeart />
            {t("Navigation_HomePage")}
          </Link>

          <div
            className={
              activeLink === "Ceremony-and-celebration" ||
              activeLink === "Buses"
                ? "active-link hamburgerNav"
                : "non-active-link hamburgerNav"
            }
            onClick={() => setOpenModal(!openModal)}
          >
            <FaFlag />
            {t("Navigation_The_Wedding")}
            <IoIosArrowForward className="TheWeddingArrow" />
          </div>
          {openModal && (
            <div className="subNavMenu">
              <Link
                to="/Ceremony-and-celebration"
                className={
                  activeLink === "Ceremony-and-celebration"
                    ? "active-link hamburgerNav"
                    : "non-active-link hamburgerNav"
                }
                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              >
                <FaHome />
                {t("Navigation_CeremonyAndCelebrationPage")}
              </Link>

              <Link
                to="/Buses"
                className={
                  activeLink === "Buses"
                    ? "active-link hamburgerNav"
                    : "non-active-link hamburgerNav"
                }
                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              >
                <FaBus />
                {t("Navigation_BusesPage")}
              </Link>
            </div>
          )}

          <Link
            to="/Album"
            className={
              activeLink === "Album"
                ? "active-link hamburgerNav"
                : "non-active-link hamburgerNav"
            }
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            <FaCamera />
            {t("Navigation_AlbumPage")}
          </Link>

          <Link
            to="/Wedding-Gift"
            className={
              activeLink === "Wedding-Gift"
                ? "active-link hamburgerNav"
                : "non-active-link hamburgerNav"
            }
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            <IoGift />
            {t("Navigation_WeddingGiftPage")}
          </Link>

          <Link
            to="/Confirm-your-attendance"
            className={
              activeLink === "Confirm-your-attendance"
                ? "active-link hamburgerNav"
                : "non-active-link hamburgerNav"
            }
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            <IoIosMail />
            {t("Navigation_ConfirmYourAttendancePage")}
          </Link>
        </div>
        <div className="flags">
          {/* <button
          className="SelectLanguage"
          onClick={() => {
            localStorage.removeItem("appLanguage");
            window.location.reload();
          }}
        >
          {t("Navigation_Languages")}
        </button> */}
          <div
            className="imageContainer"
            onClick={() => handleLanguageChange("es")}
          >
            <img src={SPflag} alt="Spanish flag" className="flagBtn" />
          </div>
          <div
            className="imageContainer"
            onClick={() => handleLanguageChange("en")}
          >
            <img src={ENflag} alt="English flag" className="flagBtn" />
          </div>
          <div
            className="imageContainer"
            onClick={() => handleLanguageChange("sq")}
          >
            <img src={ALflag} alt="Albanian flag" className="flagBtn" />
          </div>
          <div
            className="imageContainer"
            onClick={() => handleLanguageChange("el")}
          >
            <img src={GRflag} alt="Greek flag" className="flagBtn" />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigator;
