import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ErrorPage from "./Pages/ErrorPage/ErrorPage";
import Home from "./Pages/Home/Home";
import CeremonyAndCelebration from "./Pages/CeremonyAndCelebration/CeremonyAndCelebration";
import Buses from "./Pages/Buses/Buses";
import Album from "./Pages/Album/Album";
import WeddingGift from "./Pages/WeddingGift/WeddingGift";
import ConfirmYourAttendance from "./Pages/ConfirmYourAttendance/ConfirmYourAttendance";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/Ceremony-and-celebration"
          element={<CeremonyAndCelebration />}
        />
        <Route path="/Buses" element={<Buses />} />
        <Route path="/Album" element={<Album />} />
        <Route path="/Wedding-Gift" element={<WeddingGift />} />
        <Route
          path="/Confirm-your-attendance"
          element={<ConfirmYourAttendance />}
        />

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
}

export default App;
